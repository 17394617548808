<!--困难生认定-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">学生证乘车区间</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div style="width: 80%">
        <el-row :gutter="20">
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">系部:</span>
            <el-select
              v-model="bmbh"
              size="small"
              clearable
              @change="bmchange"
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in bmlist"
                :key="index"
                :label="item.bmmc"
                :value="item.bmbh"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">专业:</span>
            <el-select
              v-model="zybh"
              size="small"
              clearable
              @change="zychange"
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in zylist"
                :key="index"
                :label="item.zymc"
                :value="item.zybh"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="mr5 fcolor42 rspan">班级:</span>
            <el-select
              v-model="bjbh"
              size="small"
              clearable
              style="width: 200px"
            >
              <el-option
                v-for="(item, index) in bjlist"
                :key="index"
                :label="item.bjmc"
                :value="item.bjbh"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col style="display: flex; align-items: center">
            <span class="fcolor42 mr5 rspan">学号/姓名:</span>
            <el-input
              style="width: 200px"
              v-model="keyword"
              placeholder="请输入学号/姓名"
              size="small"
            ></el-input>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="small"
              @click="getDataPageList"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </div>
      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_edit')"
          icon="el-icon-delete"
          @click="detailInfo"
          >编辑</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="xh" label="学号"> </el-table-column>
        <el-table-column prop="xm" label="姓名"> </el-table-column>
        <el-table-column prop="xbm" label="性别">
          <template slot-scope="scope">
            {{ scope.row.xbm == 1 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column prop="bjmc" label="班级"> </el-table-column>
        <el-table-column prop="bjmc" label="家庭所在省市">
          <template slot-scope="scope">
            {{ scope.row.jtsf + '/' + scope.row.jtszs }}
          </template>
        </el-table-column>
        <el-table-column prop="bjmc" label="站台">
          <template slot-scope="scope">
            {{ scope.row.ccss + '/' + scope.row.dzzd }}
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="学生证乘车区间"
      :visible.sync="dialogFormVisible"
      width="50%"
      :close-on-click-modal="false"
      top="5vh"
    >
      <el-scrollbar style="height: 300px" class="myscrollbar">
        <el-form
          :model="forminfo"
          v-if="dialogFormVisible"
          ref="formRef"
          :rules="forminfoRules"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="学号" label-width="120px">
                <el-input
                  v-model="forminfo.XH"
                  autocomplete="off"
                  maxlength="20"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="姓名" label-width="130px">
                <el-input
                  v-model="forminfo.XM"
                  autocomplete="off"
                  maxlength="20"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="院系" label-width="130px">
                <el-input
                  v-model="forminfo.BMMC"
                  autocomplete="off"
                  maxlength="20"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="专业" label-width="130px">
                <el-input
                  v-model="forminfo.ZYMC"
                  autocomplete="off"
                  maxlength="20"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="班级" label-width="130px">
                <el-input
                  v-model="forminfo.BJMC"
                  autocomplete="off"
                  maxlength="20"
                  readonly
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="家庭住址所在省"
                label-width="130px"
                prop="JTSF"
              >
                <el-select
                  v-model="forminfo.JTSF"
                  size="small"
                  clearable
                  @change="jtsfchange"
                  style="width: 100%"
                  filterable
                >
                  <el-option
                    v-for="(item, index) in jtsflist"
                    :key="index"
                    :label="item.province"
                    :value="item.province"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="家庭住址所在市"
                label-width="130px"
                prop="JTSZS"
              >
                <el-select
                  v-model="forminfo.JTSZS"
                  size="small"
                  clearable
                  style="width: 100%"
                  filterable
                >
                  <el-option
                    v-for="(item, index) in jtslist"
                    :key="index"
                    :label="item.city"
                    :value="item.city"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="站点所在省" label-width="130px" prop="CCSS">
                <el-select
                  v-model="forminfo.CCSS"
                  size="small"
                  clearable
                  @change="ccsschange"
                  style="width: 100%"
                  filterable
                >
                  <el-option
                    v-for="(item, index) in ccsslist"
                    :key="index"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="站点" label-width="130px" prop="DZZD">
                <el-select
                  v-model="forminfo.DZZD"
                  size="small"
                  clearable
                  style="width: 100%"
                  filterable
                >
                  <el-option
                    v-for="(item, index) in zdlist"
                    :key="index"
                    :label="item.ztmc"
                    :value="item.ztmc"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-scrollbar>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  GetPageList,
  GetSF,
  GetSZS,
  GetCCSF,
  GetCCZD,
  GetCCQJFormData,
  HTSave,
} from '../../api/xsz'
import { GetListByQX, GetZYListByQX, GetBJListByQX } from '../../api/jcxx'
import { getAuthorizeButtonColumnList } from '../../api/system'
import { baseUrl } from '../../utils/http'
import moment from 'moment'
export default {
  data() {
    return {
      downurl: baseUrl,
      fileList: [],
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight - 100, // 实时屏幕高度
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      xsinfo: {},
      forminfo: {
        XM: '',
        BJMC: '',
        ZYMC: '',
        BMMC: '',
        ID: '',
        XH: '',
        JTSF: '',
        JTSZS: '',
        CCSS: '',
        DZZD: '',
      },
      bmlist: [],
      zylist: [],
      bjlist: [], // 班级列表
      bmbh: '',
      zybh: '',
      bjbh: '',
      xh: '',
      dialogFormVisible: false, // 是否显示弹出框
      dialogDetailFormVisible: false, // 是否显示详情弹出框
      jtsflist: [],
      jtslist: [],
      ccsslist: [],
      zdlist: [],
      // 表单验证规则对象
      forminfoRules: {
        JTSF: [
          {
            required: true,
            message: '请选择家庭住址所在省',
            trigger: 'blur',
          },
        ],
        JTSZS: [
          { required: true, message: '请选择家庭住址所在市', trigger: 'blur' },
        ],
        CCSS: [
          { required: true, message: '请选择乘车站点所在市', trigger: 'blur' },
        ],
        DZZD: [{ required: true, message: '请选择乘车站点', trigger: 'blur' }],
      },
    }
  },
  created() {
    GetListByQX().then((res) => {
      if (res.code === 200) {
        this.bmlist = res.data
      }
    })
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 当添加/编辑弹出框关闭时调用
    // closeDialog() {
    //   this.active = 0
    //   this.$nextTick(() => {
    //     this.forminfo = JSON.parse(JSON.stringify(this.formdata))
    //   })
    //   this.$refs.formRef.resetFields()
    // },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
        this.xh = ''
      } else {
        this.keyValue = currentRow.id
        this.xh = currentRow.xh
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      GetPageList({
        queryJson: JSON.stringify({
          keyword: this.keyword,
          bmbh: this.bmbh,
          zybh: this.zybh,
          bjbh: this.bjbh,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY年MM月DD日 HH:mm')
    },
    // 时间格式化
    dateFormat3(value) {
      if (!value) {
        return ''
      }

      return moment(value).format('YYYY-MM')
    },
    // 获取专业列表
    getZY() {
      GetZYListByQX({ BMBH: this.bmbh }).then((res) => {
        if (res.code === 200) {
          this.zylist = res.data
        }
      })
    },
    // 获取班级列表
    getBJ() {
      GetBJListByQX({ ZYBH: this.zybh }).then((res) => {
        if (res.code === 200) {
          this.bjlist = res.data
        }
      })
    },
    bmchange() {
      this.zybh = ''
      this.bjbh = ''
      this.getZY()
    },
    zychange() {
      this.bjbh = ''
      this.getBJ()
    },
    jtsfchange() {
      this.forminfo.JTSZS = ''
      GetSZS({ sfmc: this.forminfo.JTSF }).then((res) => {
        if (res.code === 200) {
          this.jtslist = res.data
        }
      })
    },
    ccsschange() {
      this.forminfo.DZZD = ''
      GetCCZD({ sf: this.forminfo.CCSS }).then((res) => {
        if (res.code === 200) {
          this.zdlist = res.data
        }
      })
    },
    detailInfo() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        GetSF().then((res) => {
          if (res.code === 200) {
            this.jtsflist = res.data
          }
        })
        GetCCSF().then((res) => {
          if (res.code === 200) {
            this.ccsslist = res.data
          }
        })
        GetCCQJFormData({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.forminfo.ID = res.data.id
            this.forminfo.XH = res.data.xh
            this.forminfo.JTSF = res.data.jtsf
            this.forminfo.JTSZS = res.data.jtszs
            this.forminfo.CCSS = res.data.ccss
            this.forminfo.DZZD = res.data.dzzd
            this.forminfo.XM = res.data.xm
            this.forminfo.BJMC = res.data.bjmc
            this.forminfo.ZYMC = res.data.zymc
            this.forminfo.BMMC = res.data.bmmc
          }
        })
        this.dialogFormVisible = true
      }
    },
    saveForm() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          HTSave(this.forminfo).then((res) => {
            if (res.code === 200) {
              this.$message.success('提交成功！')
              this.getDataPageList()
              this.dialogFormVisible = false
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 120px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #303030;
  font-weight: 700;
  padding: 5px 0;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.DivTable {
  width: 99%;
  margin: 0 auto;
  margin-top: 10px;
}
table {
  display: table;
  border-collapse: collapse;
  box-sizing: border-box;
  text-indent: initial;
  white-space: normal;
  line-height: normal;
  font-weight: normal;
  font-size: medium;
  font-style: normal;
  color: -internal-quirk-inherit;
  text-align: start;
  border: 1px solid #bdc8d3;
  font-variant: normal;
}
.DivTable tr {
  min-height: 32px;
  border: 0;
}
.DivTable .EdtTitleBack {
  background-color: #fbfafa;
  text-align: right;
  height: 32px;
  overflow: hidden;
}
.DivTable td {
  min-height: 28px;
  border: 0;
  border: 1px solid #bdc8d3;
}
.DivTable .EdtBack {
  background-color: #fff;
  text-align: left;
  height: 32px;
  overflow: hidden;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.staffBmTitle {
  margin-top: 1px;
  font-weight: 700;
  text-align: center;
  display: block;
  color: #000;
  font-size: 24px;
  margin-bottom: 10px;
}
.inputnoborder >>> .el-input__inner {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-color: #000;
}
.staffBmTable {
  width: 100%;
  font-size: 14px;
  text-align: center;
  border-color: #000;
}
.staffBmTable tr td {
  padding: 10px;
}
.cell-class {
  border-color: #000 !important;
}
.rspan {
  width: 100px !important;
  text-align: right;
}
</style>
